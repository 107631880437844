html {
  height: 100%;
}

body {
  margin: 0;
  /* font-family: "Avenir Next"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
input,
h1,
h2,
h3,
p,
button {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  letter-spacing: 0.5px;
}

h1,
h2,
h3 {
  font-weight: 400;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

h1 {
  font-size: 28px;
}

p {
  font-size: 1.1em;
  line-height: 1.8em;
}

section {
  margin-left: auto;
  margin-right: auto;

  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-top: 230px;
  /* min-width: 1500px; */
}

div.section {
  margin-bottom: 35px;
}

code {
  color: #e83e8c;
  word-break: break-word;
  font-size: 87.5%;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  background-color: #fafafa;
  padding: 4px;
  border-radius: 5px;
}

/* input {
  border-radius: 0px !important;
  padding: 10px 12px !important;
} */

/* input:focus {
  background-color: #b3d5ff !important;
} */

h3 {
  text-transform: uppercase !important;
  font-size: 10pt !important;
}

.ui.search.selection.dropdown {
  border: solid 1px #fff;;


}

.ui.search.selection.dropdown:hover {
  border: solid 1px #fff;
}